<template>
  <div class="d-flex flex-column flex-root"
       v-if="isAuthenticated && currentUser && currentUser.t">
    <!-- begin:: Header Mobile -->
    <DPHeaderMobile></DPHeaderMobile>
    <!-- end:: Header Mobile -->

    <Loader></Loader>

    <!-- begin::Body -->
    <div class="d-flex flex-row flex-column-fluid page">
      <!-- begin:: Aside Left -->
      <DPAside v-if="asideEnabled"></DPAside>
      <!-- end:: Aside Left -->

      <div id="dp_wrapper"
           class="d-flex flex-column flex-row-fluid wrapper">
        <!-- begin:: Header -->
        <DPHeader :breadcrumbs="breadcrumbs"></DPHeader>
        <!-- end:: Header -->

        <!-- begin:: Content -->
        <div id="dp_content"
             class="content d-flex flex-column flex-column-fluid">
          <!-- begin:: Content Head -->

          <!-- begin:: Content Head -->
          <DPSubheader v-if="subheaderDisplay"
                       :breadcrumbs="breadcrumbs"
                       :title="pageTitle" />
          <!-- end:: Content Head -->

          <!-- begin:: Content Body -->
          <div class="d-flex flex-column-fluid">
            <div :class="{
                'container-fluid': contentFluid,
                container: !contentFluid,
              }">
              <transition name="fade-in-up">
                <router-view />
              </transition>
            </div>
          </div>
        </div>
        <!-- end:: Content -->
        <!-- begin:: Footer -->
        <!-- <div id="dp_footer" class="footer bg-white py-4 d-flex flex-lg-column">
          <div
            class="d-flex align-items-center justify-content-between container-fluid"
          >
            <div class="text-dark">
              <span class="text-muted font-weight-bold mr-2">
                © 2023 EngageMetriq.com
              </span>
            </div>
          </div>
        </div> -->
        <!-- end:: Footer -->
      </div>
    </div>
    <DPScrollTop></DPScrollTop>
  </div>
  <div v-else>
    <Loader />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";
import HtmlClass from "@/core/services/htmlclass.service";

export default {
  name: "Layout",
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
    // kapanmama durumlarına karşı ekledim.
    setTimeout(() => {
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    }, 1000 * 5);
    // initialize html element classes
    HtmlClass.init(this.layoutConfig());
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
      "currentUser",
    ]),

    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    },
  },
  watch: {
    currentUser(val) {
      if (this.currentUser && this.currentUser.t) {
        this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      }
    },
  },
  components: {
    HtmlClass,
    DPAside: () => import("@/view/layout/aside/Aside.vue"),
    DPHeader: () => import("@/view/layout/header/Header.vue"),
    DPHeaderMobile: () => import("@/view/layout/header/HeaderMobile.vue"),
    DPSubheader: () => import("@/view/layout/subheader/Subheader.vue"),
    DPScrollTop: () => import("@/view/layout/extras/ScrollTop"),
  },
};
</script>